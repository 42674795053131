import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink, useNavigate } from "react-router-dom";
import '../../css/user-event-page.scss'
import EventDataTab from './components/EventDataTab';
import { useParams } from 'react-router-dom';
import { useGetEventWithRefMutation } from '../../store/event/api';
import ReactPlayer from 'react-player'
import { useGetEventScheduleMutation } from '../../store/schedule/api';
import { Speaker, Schedule, Workshop } from '../../utils/AppInterface';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';



const EventPage = () => {
    const { eventRef } = useParams();
    const [getEventWithRef] = useGetEventWithRefMutation();
    const [getEventSchedule] = useGetEventScheduleMutation();
    const [curEventData, setCurEventData] = useState<any>();
    const [eventSpeakers, setEventSpeakers] = useState<Speaker[]>([]);
    const [scheduleData, setScheduleData] = useState<Schedule[]>([]);
    const [workshopData, setWorkshopData] = useState<Workshop[]>([]);

    const [eventData, setEventData] = useState<any | null>([]);

    useEffect(() => {
        getEventData(eventRef)
        getEventScheduleData(eventRef)
          
    },[]);


  const getEventData = (eventRef: any) => {
    getEventWithRef(eventRef)
      .unwrap()
      .then((res) => {
        setEventSpeakers(res.speaker)
        setScheduleData(res.schedules)
        setWorkshopData(res.workshops)
        setEventData(res)
        handleCloseLoading()
        console.log(res, 'event data')
       
      })
      .catch((e) => {
        handleCloseLoading()
        // NotifyAlert(e.error, 'error')
      });
  };
  


  const getEventScheduleData = (eventRef: any) => {
    getEventSchedule(eventRef)
      .unwrap()
      .then((res) => {
       // console.log(res, 'shedui')
       /// setCurEventData(res)
      })
      .catch((e) => {
        // NotifyAlert(e.error, 'error')
      });
  };

  
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');

  const [openLoading, setOpenLoading] = React.useState(true);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };



  return (
    <div>
        <div className='first-wrapper'>
            <div className='container'>
                <div className='row gx-4 gy-4'>
                    <div className='col-md-7 col-sm-12'>
                        <Card border="light" body className='stream-body'>
                            <ReactPlayer
                                url={'https://www.youtube.com/watch?v=-9xcIhy1WbA&t=936s'}
                                height="300px"
                                width='100%'
                                controls={true}
                                loop={true}
                                light={true}
                                volume={1}
                                playing={true}
                                preload="auto"
                                autoPlay
                                id="videoplayer"
                            />
                        </Card>
                    </div>

                    <div className='col-md-5 col-sm-12'>
                        <Card border="light" body className='stream-body'>
                            <p className='text-center'>Workshop</p>
                            <div className='workshop-wrapper'>
                              <ul>

                                { workshopData.map((workshop, index) => {

                                    return <li key={index}>
                                            <div className='d-flex'>
                                              <div className='flex-fill'>
                                                <p>{ workshop.name }</p>
                                                <h6>{ workshop.description }</h6>
                                              </div>
          
                                              <div>
                                                <button className='btn btn-danger'>join</button>
                                              </div>
                                            </div>
                                          </li>
                                    })
                                }

                              </ul>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-3'>
            <div className='row'>
                <div className='col-12'>
                    <EventDataTab eventSpeakers={eventSpeakers}  scheduleData={scheduleData} eventData={eventData} />
                </div>
            </div>
        </div>
        </div>



        <Dialog fullWidth={fullWidth} open={openLoading} onClose={handleCloseLoading}>
            <DialogContent>
                <div className="d-flex justify-content-center mt-4">
                    <div>
                        <div className="spinner-border"></div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>




    </div>
  )
}

export default EventPage



//// with eventID /////
/// users can come in with an event on there url (EventId)
//// select event designation (category)



///// without eventID ////
/// show all the users added events(if added any)
//// on login, display de list of events on de dashboard, registered by the user

/// on login, go to view event page (to view all the event on the system)
/// 