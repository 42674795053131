import React from 'react'
import WorkshopTable from '../components/WorkshopTable';
import CreateWorkshopDialog from '../components/CreateWorkshopDialog';



const Workshops = () => {

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClose = () => {
    setDialogOpen(false);
  };



  return (
    <div>
      <div className='d-flex mb-4'>
        <div>
          <button className='btn btn-danger' onClick={() => setDialogOpen(true)}>Create Workshop</button>
        </div>
      </div>

      <WorkshopTable />
      <CreateWorkshopDialog dialogOpen={dialogOpen} handleClose={handleClose} />
    </div>
  )
}

export default Workshops

