import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import './table-style.scss'
import PageLoader from "../../../components/loader/PageLoader";
import { useGetSponsorMutation } from "../../../store/sponsor/api";
import { NotifyAlert } from "../../../utils/NotifyAlert";
import Avatar from '@mui/material/Avatar';



interface Sponsor {
    id: number;
    name: string;
    image: string
    event: [] | any
}


const SponsorTable = () => {

  useEffect(() => {
   getAllSponsor()
  }, []);

const [getSponsor, { isLoading }] = useGetSponsorMutation();
const [sponsorData, setSponsorData] = useState<Sponsor[]>([])


  
const getAllSponsor = () => {
  getSponsor(sponsorData)
  .unwrap()
  .then((res: any) => {
    if(res !== null){
      setSponsorData(res)
    } 
  })
  .catch((e) => {
    if(e.data !== null){
      NotifyAlert(e.data.message, 'error')
    }else{
      NotifyAlert('No server response, Server error', 'error')
    }
  });

}


  return (
    <div>
      <h5 className="table-titlename">Sponsor Table</h5>

      { isLoading ? <><PageLoader /></> : <>

          <table className="table table-hover table-bordered">
            <thead className="table-dark">
              <tr>
                  <th>Logo</th>
                  <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {sponsorData && sponsorData.map((item, index) => (
                  <tr key={index}>
                      <td>
                          <Avatar
                              alt={item.name}
                              src={item.image}
                              sx={{ width: 56, height: 56 }}
                              />
                    
                      </td>
                      <td>{ item.name }</td>
                  </tr>
              ))} 
            </tbody>
          </table>

      </> }
    </div>


  )
}

export default SponsorTable

