

import React from 'react'

const Schedule = () => {
  return (
    <div>Schedule</div>
  )
}

export default Schedule

