import React, { useEffect, useState } from 'react'
import './pages.scss'
import { useGetDataSummaryMutation } from '../../../store/dashboard/api'




const Dashboard = () => {

  useEffect(() => {
    getSummary();

  }, []);

  
  const [getDataSummary, { isLoading }] = useGetDataSummaryMutation();
  const [dataSummary, setDataSummary] = useState<any>(null)




  
const getSummary = () => {
  let eventData = null
  getDataSummary(eventData)
   .unwrap()
   .then((res: any) => {
    setDataSummary(res.dataSummary)
   
   })
   .catch((e) => {
    
   });
 
 }



  return (
    <div>
      <div className='container-fluid'>
        <div className='row g-3'>

          <div className='col-md-6 col-12'>
            <div className='dasboard-analysis-widget shadow'>
              <p>Total Users</p>
              <h6>{ dataSummary.userCount }</h6>
            </div>
          </div>

          <div className='col-md-6 col-12'>
            <div className='dasboard-analysis-widget shadow'>
              <p>Total Events</p>
              <h6>{ dataSummary.eventCount }</h6>
            </div>
          </div>

          {/* <div className='col-md-3'>
            <div className='dasboard-analysis-widget shadow'>
              <p>Total Sponsors</p>
              <h6>0</h6>
            </div>
          </div> */}

          {/* <div className='col-md-3'>
            <div className='dasboard-analysis-widget shadow'>
              <p>Total Speakers</p>
              <h6>0</h6>
            </div>
          </div> */}

        </div>

      </div>
    </div>
  )
}

export default Dashboard

