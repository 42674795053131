import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateEventMutation, useGetAllEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss'
import { CreateWorkshopValidation } from '../../../validations/WorkshopValidation';
import { NotifyAlert } from '../../../utils/NotifyAlert';
import { useCreateWorkshopMutation } from '../../../store/workshop/api';
import DymanicSelect from '../../../components/form/DymanicSelect';
import { Event, Category } from '../../../utils/AppInterface'
import { useGetAllCategoryMutation } from '../../../store/category/api';



interface dialogProps{
    dialogOpen: boolean,
    handleClose: () => void
}


const CreateWorkshopDialog:React.FC<dialogProps> = ({ dialogOpen, handleClose }) => {
  
  useEffect(() => {
    getAllEvent()
    getAllCategory()
   }, []);

    
const [open, setOpen] = React.useState(dialogOpen);
const navigate = useNavigate()
const [createWorkshop, { isLoading }] = useCreateWorkshopMutation();
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth] = React.useState("sm");

const [getEvents] = useGetAllEventMutation();
const [eventData, setEventData] = useState<Event[]>([])

const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
const [selectedEventId, setSelectedEventId] = useState('');


//// get category ///
const [getCategorys] = useGetAllCategoryMutation();
const [categoryData, setCategoryData] = useState<Category[]>([])
const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
const [selectedCategoryId, setSelectedCategoryId] = useState('');



const [formData, setFormData] = useState({
    name: "",
    liveLink: "",
    designationOfMinistry: "",
    description: "",
    eventRef: ""
   
  });

  const handleClickOpen = () => {
    setOpen(true);
  };


const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
  initialValues: { name: "",
  liveLink: "",
  designationOfMinistry: "",
  description: "",
  eventRef: ""


},
  validationSchema: CreateWorkshopValidation,
  onSubmit: () => initRequest()
})


const initRequest = () => {
  createWorkshop(values)
  .unwrap()
  .then((res: any) => {
    NotifyAlert("Workshop add successfully", 'success')
    handleClose()
    resetForm()
 
  })
  .catch((e) => {
    console.log(e, 'test')
    NotifyAlert(e.data.message, 'error')
  });
}


const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}


const handleStartTimeChange = async (value: any, context: any) => {
    setFieldValue('startTime', value)
    console.log(context, 'our contest')
    console.log(value, 'our value')
}
  

  const submitForm = () => {
    console.log(values, 'dhhddjjd')
  }


  
const getAllEvent = () => {
  getEvents(eventData)
   .unwrap()
   .then((res: any) => {
     if(res !== null){
      setEventData(res)
     } 
   })
   .catch((e) => {
    
   });
 
 }



 

 //// get all category //// 
const getAllCategory = () => {
  getCategorys(eventData)
   .unwrap()
   .then((res: any) => {
     if(res !== null){
     // setCategoryData(res)
     } 
   })
   .catch((e) => {
    
   });
 
 }


 const resetForm = () => {

  setFieldValue('name', '')
  setFieldValue('liveLink', '')
  setFieldValue('designationOfMinistry', null)
  setFieldValue('description', '')
  setFieldValue('eventRef', null)
  
 }




  return (
    <div>
        <React.Fragment>
            <Dialog 
                fullWidth={fullWidth}
                open={dialogOpen} 
                onClose={ ()=> { handleClose(); resetForm() } }>
            <DialogTitle>Create Workshop</DialogTitle>
            <DialogContent>
                <div className='form-wrapper'>
                    <div className='mt-4'>
                      <Input
                          type="text"
                          label="Name"
                          value={values.name}
                          name="name"
                          error={Boolean(errors.name)}
                          errorText={errors.name}
                          onChange={handleChange}
                          placeholder="Please enter name"
                      />
                    </div>

                    <div className='mt-4'>
                      <Input
                          type="text"
                          label="live Link"
                          value={values.liveLink}
                          name="liveLink"
                          error={Boolean(errors.liveLink)}
                          errorText={errors.liveLink}
                          onChange={handleChange}
                          placeholder="Please enter live link"
                      />
                    </div>


                    <div className='mt-4'>
                        <DymanicSelect
                          items={eventData}
                          label="Select Event"
                          value={selectedEventId}
                          labelExtractor={({ title }) => title}
                          valueExtractor={({ eventRef }) => eventRef}
                          onValueChange={(value, selectedValue) => {
                            setSelectedEvent(selectedValue);
                            setSelectedEventId(value);
                            setFieldValue('eventRef', selectedValue.eventRef)
                            setCategoryData(selectedValue.userCategories)
                            
                          }}
                          name="eventRef"
                          error={Boolean(errors.eventRef)}
                          errorText={errors.eventRef}
                          placeholder="Select event"
                          />
                    </div>

                

                    <div className='mt-4'>
                      <Input
                          type="text"
                          label="Description"
                          value={values.description}
                          name="description"
                          error={Boolean(errors.description)}
                          errorText={errors.description}
                          onChange={handleChange}
                          placeholder="Please enter description"
                      />
                    </div>



                    <div className='mt-4'>
                        <DymanicSelect
                          items={categoryData}
                          label="Select designation of ministry"
                          value={selectedCategoryId}
                          labelExtractor={({ designationOfMinistry }) => designationOfMinistry}
                          valueExtractor={({ designationOfMinistry }) => designationOfMinistry}
                          onValueChange={(value, selectedValue) => {
                            setSelectedCategory(selectedValue);
                            setSelectedCategoryId(value);
                            setFieldValue('designationOfMinistry', selectedValue.designationOfMinistry)
                            
                          }}
                          name="designationOfMinistry"
                          error={Boolean(errors.designationOfMinistry)}
                          errorText={errors.designationOfMinistry}
                          placeholder="Select designation of ministry"
                          />
                    </div>
                  
                    


                </div>
            </DialogContent>
            <DialogActions>
            <Button onClick={ ()=>{ handleClose(); resetForm()} }>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              {!isLoading ? (
                <span>Submit</span>
              ) : (
                <span>
                  <div className="spinner-border text-dark spinner-border-sm"></div>
                </span>
              )}
            </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>
    </div>
  )
}



export default CreateWorkshopDialog


