import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null,
  eventRef: null,
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // toggleTodo(state, action) {},
    setError(state, action) {
      state.error = action.payload
    },
    removeError(state, action) {
      state.error = null
    },
    setEventRef(state, action) {
      state.eventRef = action.payload
    },
  },
})

export const selectGeneral = (state: any) => state.general

export const { setError, removeError, setEventRef } = generalSlice.actions

export default generalSlice.reducer
