import React from 'react'

import '../../../css/schedule-tab.scss'
import { Schedule } from '../../../utils/AppInterface';
import moment from 'moment';


interface ScheduleTabProps {
    scheduleData: Schedule[];
  
}





const ScheduleTab: React.FC<ScheduleTabProps> = ({ scheduleData }) => {
  return (
    
    <div>
        <div className="p-3">
            <div className="row gx-3 gy-3 speaker-img">

                 {
                    scheduleData.map((schedule, index) => {
                    return  <div key={index} className="col-12">
                                <div className='schedule-item'>
                                    <h6 className='activity-text'>{ schedule?.activityName}</h6>
                                    <p className='desc-text'>{ schedule?.description }</p>
                                    <div className="date-time-wrapper">
                                        <div>
                                            <p>Start Date</p>
                                            <h6>{ moment(schedule?.startTime).format('LLLL') }</h6>
                                        </div>
                                        <div>
                                            <p>End Date</p>
                                            <h6>{ moment(schedule?.endTime).format('LLLL') }</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                    })
                } 


            </div>
        </div>
    </div>

  )
}

export default ScheduleTab



