import React from 'react'
import UsersTable from '../components/UsersTable'
import './pages.scss'


const Users = () => {



  return (
    <div className='conteiner-fluid'>
        <div className='row'>
            <div className='col-md-12'>
                <h3 className='top-title'>All Users</h3>
                <UsersTable />

            </div>
        </div>
    </div>


  )
}

export default Users

