import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import PageLoader from "../../../components/loader/PageLoader";
import { useGetSponsorMutation } from "../../../store/sponsor/api";
import { NotifyAlert } from "../../../utils/NotifyAlert";
import '../../../css/speaker-tab.scss'
import Avatar from '@mui/material/Avatar';
import { Speaker } from "../../../utils/AppInterface";



interface SpeakerTabProps {
    eventSpeakers: Speaker[];
  
}



const SpeakerTab: React.FC<SpeakerTabProps> = ({ eventSpeakers }) => {
  return (

    <div>
        <div className="p-3">
            <div className="row gx-3 gy-3 speaker-img">
                {
                    eventSpeakers.map((speaker, index) => {
                    return <div key={index} className="col-md-6 col-12">
                                    <div className="shadow-sm d-flex speaker-item">
                                        <div className="avatar-wrapper">
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={speaker.photo}
                                                sx={{ width: 56, height: 56 }}
                                                />
                                        </div>

                                        <div className="flex-fill">
                                            <h5 className="speaker-name">{speaker.name}</h5>
                                            <p className="speaker-portfolio">{speaker.portfolio}</p>
                                        </div>
                                    </div>
                                </div>
                            
                    })
                } 


            </div>
        </div>
    </div>
  )
}

export default SpeakerTab



