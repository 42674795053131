import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import './table-style.scss'
import { useGetSpeakerMutation, useDeleteSpeakerMutation } from "../../../store/speaker/api";
import PageLoader from "../../../components/loader/PageLoader";
import { NotifyAlert } from "../../../utils/NotifyAlert";
import { Speaker } from "../../../utils/AppInterface";
import Avatar from '@mui/material/Avatar';
import Modal from 'react-bootstrap/Modal';
import CreateSpeakerDialog from './CreateSpeakerDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { UpdateSpeakerValidation } from '../../../validations/SpeakerValidation';
import { useSendFileCreateEventMutation } from '../../../store/sendFile/api';
import DymanicSelect from '../../../components/form/DymanicSelect';
import { Event } from '../../../utils/AppInterface'
import InfoIcon from '@mui/icons-material/Info';
import { getToken } from '../../../utils/LocalStorage';
import axios from 'axios';
import { useGetAllEventMutation } from "../../../store/event/api";


interface dialogProps {
  sendDialogRes: boolean;
}



const SpeakerTable: React.FC<dialogProps> = ({ sendDialogRes }) => {
  
  useEffect(() => {
    getAllSpeaker();
    getAllEvent()

    if(sendDialogRes){
      getAllSpeaker()
    }

  }, [sendDialogRes]);

  const [getSpeaker, { isLoading }] = useGetSpeakerMutation();
  const [getEvents] = useGetAllEventMutation();

  const [deleteSpeaker] = useDeleteSpeakerMutation();
  const [speakerData, setSpeakerData] = useState<[]>([])
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  
  const deleteSelectedSpeaker = () => {
    if(selectedSpeaker !== null){
      let speakerId = selectedSpeaker?.speaker?.id
      deleteSpeaker(speakerId)
      .unwrap()
      .then((res: any) => {
        console.log(res)
        if(res !== null){
          NotifyAlert(res, 'success')
          getAllSpeaker()
        }
      })
      .catch((e) => {
        if(e.data !== null){
          NotifyAlert(e.data.message, 'error')
        }else{
          NotifyAlert('No server response, Server error', 'error')
        }
      });
    }else{
      NotifyAlert('No Speaker selected', 'error')

    }
  }



  const getAllSpeaker = () => {
    getSpeaker(speakerData)
    .unwrap()
    .then((res: any) => {
      if(res !== null){
        setSpeakerData(res.speakersWithEvents)
      }
    })
    .catch((e) => {
      if(e.data !== null){
        NotifyAlert(e.data.message, 'error')
      }else{
        NotifyAlert('No server response, Server error', 'error')
      }
    });
  
  }


  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };




///// Edit speaker //////
  
const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
 /// const [createEvent, { isLoading }] = useSendFileCreateEventMutation();
const [eventData, setEventData] = useState<Event[]>([])
const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
const [selectedEventId, setSelectedEventId] = useState('');
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth] = React.useState("sm");
const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);


const [formData, setFormData] = useState({
    name: "",
    bio: "",
    photo: "",
    portfolio: '',
    event: ""
  });



const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
  initialValues: { 
    name: selectedSpeaker?.speaker?.name,
    bio: selectedSpeaker?.speaker?.bio,
    photo: "",
    portfolio: selectedSpeaker?.speaker?.portfolio,
    event: ""
},
  enableReinitialize: true,
  validationSchema: UpdateSpeakerValidation,
  onSubmit: () => initRequest()
})




  
const handleEditDialog = (speaker: any) => {
  setSelectedSpeaker(speaker); 

  setFieldValue('name', speaker.speaker.name)
  setFieldValue('bio', speaker.speaker.bio)
  setFieldValue('photo', speaker.speaker.photo)
  setFieldValue('portfolio', speaker.speaker.portfolio)
  setFieldValue('event', speaker.eventTitle)

  errors.bio = ''

  setOpenUpdateDialog(true)
  console.log(speaker, 'speaker')


  touched.bio = true
  touched.name = true
  touched.portfolio = true
  touched.event = true
  
  }


const initRequest = async () => {

  var bodyFormData = new FormData();
  bodyFormData.append('file', values.photo);
  bodyFormData.append('name', values.name);
  bodyFormData.append('bio', values.bio);
  bodyFormData.append('portfolio', values.portfolio);
  bodyFormData.append('eventRef', values.event);
  

  const config = {
    headers: {
      Authorization: getToken(),
      'content-type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios.post('https://ministerial-conference.onrender.com/api/v1/events/speaker/add', bodyFormData, config);

    if (response.status === 201) {
      setIsLoadingUpdate(false)
        NotifyAlert('Speaker updated successfully', 'success')
        handleCloseUpdateDialog()
        resetForm()
    
    }else{
      setIsLoadingUpdate(false)
    }
  } catch (error) {
    setIsLoadingUpdate(false)
    console.error('An error occurred:', error);
  }

}



const handleFileUpload = (data: any) => {
  setFieldValue('photo', data.target.files[0])
}



const resetForm = () => {
  // setFieldValue('name', '')
  // setFieldValue('bio', '')
  // setFieldValue('photo', '')
  // setFieldValue('portfolio', '')
  // setFieldValue('event', '')
}

const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}


const handleCloseUpdateDialog = () => {
  setOpenUpdateDialog(false)
}




const getAllEvent = () => {
  getEvents(eventData)
   .unwrap()
   .then((res: any) => {
     if(res !== null){
      setEventData(res)
     } 
   })
   .catch((e) => {
    
   });
 
 }



  return (
    <div>
        <h5 className="table-titlename">Speaker Table</h5>

          { isLoading ? <><PageLoader /></> : <> 
              <table className="table table-hover table-bordered">
                <thead className="table-dark">
                  <tr>
                      <th>Event Name</th>
                      <th>Name</th>
                      <th>Bio</th>
                      <th>Portfolio</th>
                      <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {speakerData && speakerData.map((item: any, index) => (
                      <tr key={index}>
                        <td> { item?.event.title }</td>
                          <td>
                            <div className="speaker-name-column">
                              <div>
                                  <Avatar
                                    alt={item?.speaker.name}
                                    src={item?.speaker.photo}
                                    sx={{ width: 56, height: 56 }}
                                  />
                              </div>
                              <div>{item?.speaker.name} </div>
                            </div>
                          </td>
                          <td>{ item?.speaker.bio }</td>
                          <td>{ item?.speaker.portfolio }</td>
                          <td>
                            <button className="btn btn-secondary mr-2" onClick={() => { handleEditDialog(item)  }}>Edit</button>
                            <button className="btn btn-danger" onClick={() => { handleShow(); setSelectedSpeaker(item) }}>Delete</button>
                          </td>
                      </tr>
                  ))} 
                </tbody>
              </table>
          </> }







          {/* Update Dialog */}

          <Dialog 
                fullWidth={fullWidth}
                open={openUpdateDialog} 
                onClose={handleCloseUpdateDialog}>
            <DialogTitle>Update Speaker</DialogTitle>
            <DialogContent>
                <div className='form-wrapper'>
                    <div className='mt-4'>
                    <Input
                        type="text"
                        label="Full Name"
                        value={values.name}
                        name="name"
                        error={Boolean(errors.name)}
                        errorText={errors.name}
                        onChange={handleChange}
                        placeholder="Please enter speaker full name"
                    />
                    </div>

                    <div className='mt-4'>
                    <Input
                        type="text"
                        label="Bio"
                        value={values.bio}
                        name="bio"
                        error={Boolean(errors.bio)}
                        errorText={errors.bio}
                        onChange={handleChange}
                        placeholder="Please enter bio"
                    />
                    </div>


                    <div className='mt-4'>
                        <Input
                            type="text"
                            label="Portfolio"
                            value={values.portfolio}
                            name="portfolio"
                            error={Boolean(errors.portfolio)}
                            errorText={errors.portfolio}
                            onChange={handleChange}
                            placeholder="Please enter portfolio"
                        />
                    </div>

                    <div className='mt-4'>
                      <label htmlFor="Upload Photo" className='input-label'>Upload Photo</label>
                        <input
                          type='file'
                          id="file"
                          name="photo"
                          placeholder="upload file"
                          onChange={handleFileUpload}
                          className='input-text'
                        />

                        {Boolean(errors.photo && errors.photo)  && <><p className="error"> <InfoIcon className='error-icon' /> { errors.photo }</p></> }
                    </div>

                    <div className='mt-4'>
                        <DymanicSelect
                          items={eventData}
                          label="Select Event"
                          value={selectedEventId}
                          labelExtractor={({ title }) => title}
                          valueExtractor={({ eventRef }) => eventRef}
                          onValueChange={(value, selectedValue) => {
                            setSelectedEvent(selectedValue);
                            setSelectedEventId(value);
                            setFieldValue('event', selectedValue.eventRef)
                          }}
                          name="event"
                          error={Boolean(errors.event)}
                          errorText={errors.event}
                          placeholder="Select event"
                          />

                    </div>

                </div>
            </DialogContent>
            <DialogActions>
            <Button onClick={ () => {handleCloseUpdateDialog()}}>Cancel</Button>
            <Button onClick={() => { }}>
              {!isLoading ? (
                <span>Submit</span>
              ) : (
                <span>
                  <div className="spinner-border text-dark spinner-border-sm"></div>
                </span>
              )}
              
            </Button>
            </DialogActions>
            </Dialog>




          {/* Delete Speaker */}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Do you still want to proceed ?</Modal.Title>
            </Modal.Header>
              <Modal.Body>
                  <div className="button-wrapper-proceed">
                    <div>
                        <button className="btn btn-success btn-block" onClick={ () => { deleteSelectedSpeaker() }}>Yes</button>
                    </div>
                    <div>
                      <button className="btn btn-danger btn-block" onClick={handleClose}>No</button>
                    </div>
                  </div>
              </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

      <CreateSpeakerDialog dialogOpen={dialogOpen} handleClose={handleCloseDialog} currentSpeakerData={selectedSpeaker} />
    </div>
  )
}

export default SpeakerTable


