import { configureStore } from '@reduxjs/toolkit'
import { userApi } from './user/api'
import { adminApi } from './admin/api'
import { speakerApi } from './speaker/api'
import { sponsorApi } from './sponsor/api'
import { workshopApi } from './workshop/api'
import { eventApi } from './event/api'
import { scheduleApi } from './schedule/api'
import { sendFileApi } from './sendFile/api'
import { categoryApi } from './category/api'
import generalReducer from './general/generalSlice'
import { dashboardApi } from './dashboard/api'

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [speakerApi.reducerPath]: speakerApi.reducer,
    [sponsorApi.reducerPath]: sponsorApi.reducer,
    [workshopApi.reducerPath]: workshopApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [sendFileApi.reducerPath]: sendFileApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,

    general: generalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      adminApi.middleware,
      speakerApi.middleware,
      sponsorApi.middleware,
      workshopApi.middleware,
      eventApi.middleware,
      scheduleApi.middleware,
      sendFileApi.middleware,
      categoryApi.middleware,
      dashboardApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
