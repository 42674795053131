import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateEventMutation, useGetAllEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss'
import { CreateSpeakerValidation } from '../../../validations/SpeakerValidation';
import { useSendFileCreateEventMutation } from '../../../store/sendFile/api';
import DymanicSelect from '../../../components/form/DymanicSelect';
import { Event } from '../../../utils/AppInterface'
import InfoIcon from '@mui/icons-material/Info';
import { getToken } from '../../../utils/LocalStorage';
import axios from 'axios';
import { NotifyAlert } from '../../../utils/NotifyAlert';




interface dialogProps{
    dialogOpen: boolean,
    handleClose: () => void,
    currentSpeakerData: any

}



const CreateSpeakerDialog:React.FC<dialogProps> = ({ dialogOpen, handleClose, currentSpeakerData }) => {


  useEffect(() => {
    getAllEvent()

    setTimeout(() => {
    }, 5000)

   }, []);

    
const [open, setOpen] = React.useState(dialogOpen);
const navigate = useNavigate()
 /// const [createEvent, { isLoading }] = useSendFileCreateEventMutation();
const [getEvents] = useGetAllEventMutation();
const [eventData, setEventData] = useState<Event[]>([])
const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
const [selectedEventId, setSelectedEventId] = useState('');
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth] = React.useState("sm");
const [isLoading, setIsLoading] = React.useState(false);


const [formData, setFormData] = useState({
    name: "",
    bio: "",
    photo: "",
    portfolio: '',
    event: ""
  });


  const handleClickOpen = () => {
    setOpen(true);
  };



const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
  initialValues: { 
    name: "",
    bio: "",
    photo: "",
    portfolio: '',
    event: ""
},
  validationSchema: CreateSpeakerValidation,
  onSubmit: () => initRequest()
})


const initRequest = async () => {

  var bodyFormData = new FormData();
  bodyFormData.append('file', values.photo);
  bodyFormData.append('name', values.name);
  bodyFormData.append('bio', values.bio);
  bodyFormData.append('portfolio', values.portfolio);
  bodyFormData.append('eventRef', values.event);
  

  const config = {
    headers: {
      Authorization: getToken(),
      'content-type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios.post('https://ministerial-conference.onrender.com/api/v1/events/speaker/add', bodyFormData, config);

    if (response.status === 201) {
        setIsLoading(false)
        NotifyAlert('Speaker added successfully', 'success')
        handleClose()
        resetForm()
    
    }else{
      setIsLoading(false)
    }
  } catch (error) {
    setIsLoading(false)
    console.error('An error occurred:', error);
  }

}




const getAllEvent = () => {
  getEvents(eventData)
   .unwrap()
   .then((res: any) => {
     if(res !== null){
      setEventData(res)
     } 
   })
   .catch((e) => {
    
   });
 
 }


const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}


// const handleStartTimeChange = async (value: any, context: any) => {
//     setFieldValue('startTime', value)
//     console.log(context, 'our contest')
//     console.log(value, 'our value')

// }
  

  // const submitForm = () => {
  //   console.log(values, 'dhhddjjd')
  // }



  const handleFileUpload = (data: any) => {
      setFieldValue('photo', data.target.files[0])
  }



  const resetForm = () => {
    setFieldValue('name', '')
    setFieldValue('bio', '')
    setFieldValue('photo', '')
    setFieldValue('portfolio', '')
    setFieldValue('event', '')
  }




  return (
    <div>
        <React.Fragment>

            <Dialog 
                fullWidth={fullWidth}
                open={dialogOpen} 
                onClose={handleClose}>
            <DialogTitle>Create Speaker</DialogTitle>
            <DialogContent>
                <div className='form-wrapper'>
                    <div className='mt-4'>
                    <Input
                        type="text"
                        label="Full Name"
                        value={values.name}
                        name="name"
                        error={Boolean(errors.name)}
                        errorText={errors.name}
                        onChange={handleChange}
                        placeholder="Please enter speaker full name"
                    />
                    </div>

                    <div className='mt-4'>
                    <Input
                        type="text"
                        label="Bio"
                        value={values.bio}
                        name="bio"
                        error={Boolean(errors.bio)}
                        errorText={errors.bio}
                        onChange={handleChange}
                        placeholder="Please enter bio"
                    />
                    </div>


                    <div className='mt-4'>
                        <Input
                            type="text"
                            label="Portfolio"
                            value={values.portfolio}
                            name="portfolio"
                            error={Boolean(errors.portfolio)}
                            errorText={errors.portfolio}
                            onChange={handleChange}
                            placeholder="Please enter portfolio"
                        />
                    </div>

                    <div className='mt-4'>
                      <label htmlFor="Upload Photo" className='input-label'>Upload Photo</label>
                        <input
                          type='file'
                          id="file"
                          name="photo"
                          placeholder="upload file"
                          onChange={handleFileUpload}
                          className='input-text'
                        />

                        {Boolean(errors.photo && errors.photo)  && <><p className="error"> <InfoIcon className='error-icon' /> { errors.photo }</p></> }
                    </div>

                    <div className='mt-4'>
                        <DymanicSelect
                          items={eventData}
                          label="Select Event"
                          value={selectedEventId}
                          labelExtractor={({ title }) => title}
                          valueExtractor={({ eventRef }) => eventRef}
                          onValueChange={(value, selectedValue) => {
                            setSelectedEvent(selectedValue);
                            setSelectedEventId(value);
                            setFieldValue('event', selectedValue.eventRef)
                            
                          }}
                          name="event"
                          error={Boolean(errors.event)}
                          errorText={errors.event}
                          placeholder="Select event"
                          />

                    </div>

                </div>
            </DialogContent>
            <DialogActions>
            <Button onClick={ () => {resetForm(); handleClose()}}>Cancel</Button>
            <Button onClick={() => {handleSubmit()}}>
              {!isLoading ? (
                <span>Submit</span>
              ) : (
                <span>
                  <div className="spinner-border text-dark spinner-border-sm"></div>
                </span>
              )}
              
            </Button>
            </DialogActions>
            </Dialog>

        </React.Fragment>
    </div>

  )
}

export default CreateSpeakerDialog
