import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import './table-style.scss'
import { useGetAllEventMutation } from "../../../store/event/api";
import PageLoader from "../../../components/loader/PageLoader";
import moment from 'moment';

import { Event } from "../../../utils/AppInterface";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { CreateEventValidation, UpdateEventValidation } from '../../../validations/EventValidation';
import { useCreateEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss';
import { NotifyAlert } from '../../../utils/NotifyAlert';
import InfoIcon from '@mui/icons-material/Info';
import { useSendCreateEventDataMutation } from '../../../store/sendFile/api';
import axios from 'axios';
import { getToken } from '../../../utils/LocalStorage';


interface dialogProps {
  sendDialogRes: boolean;
}



const EventsTable: React.FC<dialogProps> = ({ sendDialogRes }) => {

  const navigate = useNavigate();
  useEffect(() => {
    getAllEvent()

    if(sendDialogRes){
      getAllEvent()
    }

   }, [sendDialogRes]);
 
 const [getEvents, { isLoading }] = useGetAllEventMutation();
 const [eventData, setEventData] = useState<Event[]>([])
 
 
   
 const getAllEvent = () => {
  getEvents(eventData)
   .unwrap()
   .then((res: any) => {
     if(res !== null){
      setEventData(res)
     } 
   })
   .catch((e) => {
     if(e.data !== null){
      // NotifyAlert(e.data.message, 'error')
     }else{
       NotifyAlert('No server response, Server error', 'error')
     }
   });
 
 }
 



  const goToViewEvent = (event: string) => {
    navigate(`/admin/view-event/${event}`)
  }



  /////// Edit Event //////
  const [haveSetStartTime, setHaveSetStartTime] = React.useState(false);
  const [haveSetEndTime, setHaveSetEndTime] = React.useState(false);

  const [openDialogUpdate, setOpenDialogUpdate] = React.useState(false);
  const [currentEventId, setCurrentEventId] = React.useState(0);

 //// const [createEvent, { isLoading }] = useSendCreateEventDataMutation();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null)


  const handleEditDialogClose = () => {
    setOpenDialogUpdate(false)
  }

  const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: {
      title: selectedEvent?.title,
      description: selectedEvent?.description,
      liveStreamLink: selectedEvent?.liveStreamLink,
      startDate: selectedEvent?.startDate,
      startTime: '',
      endDate: selectedEvent?.endDate,
      endTime: '',
      file: '',
      designationOfMinistry: selectedEvent?.designationOfMinistry,
      id: selectedEvent?.id,
      eventRef: selectedEvent?.eventRef,
      formerStartTime: selectedEvent?.formerStartTime,
      formerEndTime: selectedEvent?.formerEndTime,

    },
    enableReinitialize: true,
    validationSchema: UpdateEventValidation,
    onSubmit: () => initRequest(),
  });

   const initRequest = async () => {

    setIsLoadingUpdate(true)
    let currentStartTime = '00:00:00';
    if(haveSetStartTime){
      currentStartTime = values.startTime
    }else{
      currentStartTime = formatSelectedTime(values.formerStartTime)
      
    }

    let currentEndTime = '00:00:00';
    if(haveSetEndTime){
      currentEndTime = values.endTime
    }else{
      currentEndTime = formatSelectedTime(values.formerEndTime)
    }


    const startDateTime = `${values.startDate}T${currentStartTime}:00`;
    const endDateTime = `${values.endDate}T${currentEndTime}:00`;

    var bodyFormData = new FormData();
    bodyFormData.append('file', values.file);
    bodyFormData.append('title', values.title);
    bodyFormData.append('eventStartDateTime', startDateTime);
    bodyFormData.append('eventEndDateTime', endDateTime);
    bodyFormData.append('description', values.description);
    bodyFormData.append('liveStreamLink', values.liveStreamLink);
    bodyFormData.append('designationOfMinistry', values.designationOfMinistry);

    const config = {
      headers: {
        Authorization: getToken(),
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const response = await axios.put(`https://ministerial-conference.onrender.com/api/v1/events/update/${values.eventRef}`, bodyFormData, config);

      if (response.status === 200) {
        setIsLoadingUpdate(false)
          NotifyAlert('Event updated successfully', 'success')
          handleEditDialogClose()
          getAllEvent()
          setFieldValue('title', '');
          setFieldValue('description', '');
          setFieldValue('liveStreamLink', '');
          setFieldValue('startDate', '');
          setFieldValue('startTime', '');
          setFieldValue('endDate', '');
          setFieldValue('endTime', '');
          setFieldValue('file', '');
          setFieldValue('designationOfMinistry', '');
      
      }else{
        setIsLoadingUpdate(false)
      }
    } catch (error) {
      setIsLoadingUpdate(false)
      console.error('An error occurred:', error);
    }

  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setFieldValue(name, value);
  };

  const formatTime = (value: any) => {
    return `${value.$H.toString().padStart(2, '0')}:${value.$m.toString().padStart(2, '0')}`;
  };

  const formatSelectedTime = (value: any) => {
    let curTime = value.split(':');
    return `${curTime[0].toString().padStart(2, '0')}:${curTime[1].toString().padStart(2, '0')}`;
  };


  const handleStartTimeChange = (value: any, context: any) => {
    const setStartTime = formatTime(value);
    setFieldValue('startTime', setStartTime);
    setHaveSetStartTime(true)
  };

  const handleEndTimeChange = (value: any, context: any) => {
    const setEndTime = formatTime(value);
    setFieldValue('endTime', setEndTime);
    setHaveSetEndTime(true)
  };

  
  const handleFileUpload = (data: any) => {
    // console.log(data.target.files[0], 'file')
     setFieldValue('file', data.target.files[0])
 }

 
  
const handleEditDialog = (event: any) => {

  let startDate = event.eventStartDateTime.split('T');
  let endDate = event.eventEndDateTime.split('T');
  let designationOfMinistry = ''
  let currentCategory = event.userCategories


  for (let index = 0; index < currentCategory.length; index++) {
    const element = currentCategory[index];
    designationOfMinistry = designationOfMinistry + element.designationOfMinistry + ', '
  }

 
 /// console.log(event, 'test des')
 /// console.log(designationOfMinistry, 'event id des')


  let newEventData = {
    title: event?.title,
    description: event?.description,
    liveStreamLink: event?.liveStreamLink,
    startDate: startDate[0],
    startTime: event?.eventStartDateTime,
    endDate: endDate[0],
    endTime: event?.eventStartDateTime,
    file: '',
    designationOfMinistry: designationOfMinistry,
    id: event?.id,
    eventRef: event?.eventRef,
    formerEndTime: endDate[1],
    formerStartTime: startDate[1]
  }

  setSelectedEvent(newEventData); 
  console.log(newEventData, 'test')
  setOpenDialogUpdate(true)
  
  }



  return (
    <div>
        <h5 className="table-titlename">Event Table</h5>
        { isLoading ? <><PageLoader /></> : <>

              <table className="table table-hover table-bordered">
                <thead className="table-dark">
                  <tr>
                      <th>Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Description</th>
                      <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    {eventData.map((item, index) => (
                          <tr key={index}>
                              <td>{item.title}</td>
                          
                              <td>{moment(item.eventStartDateTime).format('LLLL')}</td>
                              <td>{moment(item.eventEndDateTime).format('LLLL')}</td>
                              <td>{ item.description }</td>

                              <td>
                                <button className="btn btn-secondary mr-2" onClick={() => { handleEditDialog(item)  }}>Edit</button>
                                <button className="btn btn-primary" onClick={() => { goToViewEvent(item.eventRef); }}>view</button>
                              </td>
                          </tr>
                      ))}
                </tbody>
              </table>

        </> }



        <Dialog fullWidth={fullWidth} open={openDialogUpdate} onClose={handleEditDialogClose}>
          <DialogTitle>Create Event</DialogTitle>
          <DialogContent>
            <div className="form-wrapper">
              <div className="mt-4">
                <Input
                  type="text"
                  label="Title"
                  value={values.title}
                  name="title"
                  error={Boolean(errors.title)}
                  errorText={errors.title}
                  onChange={handleChange}
                  placeholder="Please enter title"
                />
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Description"
                  value={values.description}
                  name="description"
                  error={Boolean(errors.description)}
                  errorText={errors.description}
                  onChange={handleChange}
                  placeholder="Please enter description"
                />
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Live Stream Link"
                  value={values.liveStreamLink}
                  name="liveStreamLink"
                  error={Boolean(errors.liveStreamLink)}
                  errorText={errors.liveStreamLink}
                  onChange={handleChange}
                  placeholder="Please enter live stream link"
                />
              </div>

              <div className="mt-4">
                <div className="date-picker-wrapper">
                  <div>
                    <Input
                      type="date"
                      label="Start Date"
                      value={values.startDate}
                      name="startDate"
                      error={Boolean(errors.startDate)}
                      errorText={errors.startDate}
                      onChange={handleChange}
                      placeholder="Please enter start date"
                    />
                  </div>

                  <div>
                    <InputDatePicker
                      label="Start Time"
                      value={values.startTime}
                      onChange={handleStartTimeChange}
                      error={Boolean(errors.startTime)}
                      errorText={errors.startTime}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="date-picker-wrapper">
                  <div>
                    <Input
                      type="date"
                      label="End Date"
                      value={values.endDate}
                      name="endDate"
                      error={Boolean(errors.endDate)}
                      errorText={errors.endDate}
                      onChange={handleChange}
                      placeholder="Please enter end date"
                    />
                  </div>

                  <div>
                    <InputDatePicker
                      label="End Time"
                      value={values.endTime}
                      onChange={handleEndTimeChange}
                      error={Boolean(errors.endTime)}
                      errorText={errors.endTime}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Event Designation"
                  value={values.designationOfMinistry}
                  name="designationOfMinistry"
                  error={Boolean(errors.designationOfMinistry)}
                  errorText={errors.designationOfMinistry}
                  onChange={handleChange}
                  placeholder="Please enter event designation"
                />
                <small>separate each designation with a ','</small>
              </div>


              <div className='mt-4'>
                <label htmlFor="Upload Photo" className='input-label'>Upload Photo</label>
                  <input
                    type='file'
                    id="file"
                    name="file"
                    placeholder="upload file"
                    onChange={handleFileUpload}
                    className='input-text'
                  />

                  {Boolean(errors.file && errors.file)  && <><p className="error"> <InfoIcon className='error-icon' /> { errors.file }</p></> }
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              {!isLoadingUpdate ? (
                <span>Submit</span>
              ) : (
                <span>
                  <div className="spinner-border text-dark spinner-border-sm"></div>
                </span>
              )}
            </Button>
          </DialogActions>
        </Dialog>




    </div>
  )
}

export default EventsTable

