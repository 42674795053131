import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './navbar.scss'
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import GroupIcon from '@mui/icons-material/Group';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import ismLogo from '../../../public/images/ism-logo.jpeg'


const SideBar = () => {
    const navigate = useNavigate();

  return (
        <div className='sidebar-wrapper'>
            <div className='text-center logo-header'>
                {/* <p className="sidebar__text">Logo</p> */}
                <img src={ismLogo} className='img-fluid logo-img' alt="" />
            </div>

            <List>
                <Link to={"/admin/dashboard"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <DashboardIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Dashboard" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={"/admin/events"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <DashboardIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Events" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={"/admin/users"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <GroupIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Users" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>

                <Link to={"/admin/sponsors"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <NewspaperIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Sponsors" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>

                {/* <Link to={"/admin/schedule"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <WorkHistoryIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Schedule" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link> */}

                <Link to={"/admin/speaker"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <RecordVoiceOverIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Speaker" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>


                <Link to={"/admin/workshops"}>
                    <ListItem disablePadding>
                            <ListItemButton>
                                <div className="sidebar__menu">
                                    <ListItemIcon className='link-icon'>
                                        <div className="sidebar__icon_i">
                                            <MapsHomeWorkIcon />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText className='nav-item-link sidebar__menu__text' primary="Workshops" />
                                </div>
                            </ListItemButton>
                    </ListItem>
                </Link>
            </List>
        </div>
  )
}

export default SideBar

