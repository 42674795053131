import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import './table-style.scss'
import { useGetWorkshopMutation } from "../../../store/workshop/api";
import PageLoader from "../../../components/loader/PageLoader";
import { NotifyAlert } from "../../../utils/NotifyAlert";
import { Workshop } from "../../../utils/AppInterface";







const WorkshopTable = () => {

  useEffect(() => {
    getAllWorkshop()
   }, []);

  const [getWorkshop, { isLoading }] = useGetWorkshopMutation();
  const [workshopData, setWorkshopData] = useState<any[]>([])

  
const getAllWorkshop = () => {
  getWorkshop(workshopData)
  .unwrap()
  .then((res: any) => {
    if(res !== null){
      setWorkshopData(res.workshops)
    } 
  })
  .catch((e) => {
    if(e.data !== null){
      NotifyAlert(e.data.message, 'error')
    }else{
      NotifyAlert('No server response, Server error', 'error')
    }
  });

}

   
  return (
    <div>
        <h5 className="table-titlename">Workshop Table</h5>

        { isLoading ? <><PageLoader /></> : <>

          <table className="table table-hover table-bordered">
            <thead className="table-dark">
              <tr>
                  <th>Event Name</th>
                  <th>Name</th>
                  <th>Designation Of Ministry</th>
                  <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {workshopData && workshopData.map((item, index) => (
                  <tr key={index}>
                     <td>
                        { item.eventTitle }
                      </td>
                      <td>
                        { item.name }
                      </td>
                      <td>{ item.workshop.designationOfMinistry }</td>
                      <td>{ item.workshop.description }</td>
                  </tr>
              ))} 
            </tbody>
          </table>

        </> }

    </div>
  )
}

export default WorkshopTable

