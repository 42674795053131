import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/login.scss'
import Input from '../../components/form/Input';
import { useFormik } from 'formik';
import { LoginValidation } from "./../../validations/AuthValidation";
import { NavLink, useNavigate } from "react-router-dom";
import { useLoginMutation } from '../../store/user/api';
import { NotifyAlert } from '../../utils/NotifyAlert';
import Navbar from 'react-bootstrap/Navbar';
import { saveToken, saveUserData, getUserType, getToken, saveEventRef } from '../../utils/LocalStorage';
import { selectGeneral, setEventRef } from './../../store/general/generalSlice';
import imgeLogo from '../../public/images/youth-logo.png'
import imageBanner from '../../public/images/ls-banner.jpg'
import ismLogo from '../../public/images/ism-logo.jpeg'




const Login = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const event = queryParams.get("event")
    if(event !== '' && event !== null){
      saveEventRef(event)
    }

    if(getUserType() === 'user' && getToken() !== null){
      navigate('/user/dashboard')
    }

},[]);




  const [loginUser, { isLoading }] = useLoginMutation();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
  initialValues: { email: '', password: '' },
  validationSchema: LoginValidation,
  onSubmit: () => initRequest()
})

const initRequest = () => {
  loginUser(values)
  .unwrap()
  .then((res) => {
    NotifyAlert('Login successful', 'success')
    saveToken(res.token);
    saveUserData(res.user)
    navigate('/user/dashboard')
  })
  .catch((e) => {
    if(e.data && e.data !== null){
      NotifyAlert(e.data.message, 'error')
    }else{
      NotifyAlert('No server response, Server error', 'error')
    }
  });
}


const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}


  return (
    <>
        {/* Nav Bar */}
        <div>
            <Navbar className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand>
                        <NavLink className="navbar-item" to="/">
                            <img src={ismLogo} className='img-fluid logo-img' alt="" />
                        </NavLink>
                      </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <NavLink className="navbar-item" to="/auth/register">
                            Sign Up
                        </NavLink>
                    </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>


        <div className='container'>
            <Row className='gx-5 gy-5 mt-5'>
                <Col xs={12} md={4}></Col>
                <Col xs={12} md={4}>
                  <div>
                    <h4 className='text-center welcome-text'>Welcome back!</h4>
                    <p className='text-center sign-text'> Sign in to access your account. <br /> Not registered?  
                      <NavLink className="navbar-item-register"
                            to="/auth/register"
                        >
                            Create an account
                        </NavLink>
                    </p>

                    <div className='d-flex justify-content-center'>
                        <img src={ismLogo} className='img-fluid' alt="" />
                    </div>
                  </div>

                  <div className='form-wrapper'>
                    <div className='logo-company-wrapper'>
                      {/* <div>
                        <img src={ismLogo} className='img-fluid host-logo' alt="" />
                      </div> */}
                      <div>
                        {/* <h6 className='text-center ignite-text'>Ignite! | 2023 MinistrySmart Conference</h6> */}
                        <p className='text-center host-text'>Hosted by <b>International School of Ministry</b></p>
                      </div>
                    </div>

                    <div className='mt-4'>
                      <Input
                        type="email"
                        label="Email"
                        value={values.email}
                        name="email"
                        error={Boolean(errors.email)}
                        errorText={errors.email}
                        onChange={handleChange}
                        placeholder="Please enter your email"
                      />
                    </div>

                    
                    <div className='mt-4'>
                      <Input
                        type="password"
                        label="Password"
                        value={values.password}
                        name="password"
                        error={Boolean(errors.password)}
                        errorText={errors.password}
                        onChange={handleChange}
                        placeholder="Please enter your password"
                      />
                    </div>

                    <div className='mt-4'>
                      <button disabled={isLoading} onClick={() => {handleSubmit()}} className='btn-block login-btn'>
                        { !isLoading ? <><span>Continue to Sign In</span></>  : <><span><div className="spinner-border text-light spinner-border-sm"></div></span></> }
                      </button>
                    </div>
                  </div>
                </Col>
            </Row>
        </div>
    </>

  )
}

export default Login

function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
