


import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss'
import { CreateSponsorValidation } from '../../../validations/SponsorValidation';
import { useCreateSponsorMutation } from '../../../store/sponsor/api';
import { NotifyAlert } from '../../../utils/NotifyAlert';




interface dialogProps{
    dialogOpen: boolean,
    handleClose: () => void
}




const CreateSponsor:React.FC<dialogProps> = ({ dialogOpen, handleClose }) => {

    
const [open, setOpen] = React.useState(dialogOpen);
const navigate = useNavigate()
const [createSponsor, { isLoading }] = useCreateSponsorMutation();
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth] = React.useState("sm");

const [formData, setFormData] = useState({
    name: "",
  });


  const handleClickOpen = () => {
    setOpen(true);
  };



const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
  initialValues: { 
    name: "",
},
  validationSchema: CreateSponsorValidation,
  onSubmit: () => initRequest()
})


const initRequest = () => {

  createSponsor(values)
  .unwrap()
  .then((res: any) => {
    NotifyAlert('Sponsor added successfully', 'success')
    handleClose()
     
  })
  .catch((e) => {
    NotifyAlert(e.error, 'error')
  });
}


const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}


const handleStartTimeChange = async (value: any, context: any) => {
    setFieldValue('startTime', value)
    console.log(context, 'our contest')
    console.log(value, 'our value')

}
  

  const submitForm = () => {
    console.log(values, 'dhhddjjd')
  }




  return (
    <div>
          <React.Fragment>
            <Dialog 
                fullWidth={fullWidth}
                open={dialogOpen} 
                onClose={handleClose}>
            <DialogTitle>Create Sponsor</DialogTitle>
            <DialogContent>
                <div className='form-wrapper'>
                    <div className='mt-4'>
                    <Input
                        type="text"
                        label="Name"
                        value={values.name}
                        name="name"
                        error={Boolean(errors.name)}
                        errorText={errors.name}
                        onChange={handleChange}
                        placeholder="Enter the sponsor name"
                    />
                    </div>

                </div>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={isLoading} onClick={() => {handleSubmit()}}>
                { !isLoading ? <><span>Submit</span></>  : <><span><div className="spinner-border text-dark spinner-border-sm"></div></span></> }
            </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>
    </div>
  )
}

export default CreateSponsor


