import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_ColumnDef } from 'material-react-table'; // If using TypeScript (optional, but recommended)
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import { useGetAllUsersMutation } from "../../../store/user/api";
import PageLoader from "../../../components/loader/PageLoader";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { User } from '../../../utils/AppInterface'
//If using TypeScript, define the shape of your data (optional, but recommended)




const UsersTable = () => {

  useEffect(() => {
    getAllUsers()
  }, []);


  const [getUsers, { isLoading }] = useGetAllUsersMutation();
  const [userData, setUserData] = useState<User[]>([])


 

  const getAllUsers = () => {
    let data = null
    getUsers(data)
    .unwrap()
    .then((res: any) => {
      if(res !== null){
        setUserData(res.accountInfo)

       } 
    })
    .catch((e) => {
    // NotifyAlert(e.error, 'error')
    });

  }

  const handleSelectedOption = (data: any) => {
    console.log(data.original, 'selected data')
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  

  return (
    <div>

        { isLoading ? <> <PageLoader /> </> : <>

              <table className="table table-hover table-bordered">
                <thead className="table-dark">
                  <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Gender</th>
                      <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {userData && userData.map((item, index) => (
                      <tr key={index}>
                          <td>{ item.title} {item.firstName} {item.lastName} </td>
                          <td>{ item.email }</td>
                          <td>{ item.gender }</td>
                          <td>{ item.phoneNumber }</td>
                      
                      </tr>
                  ))} 
                </tbody>
              </table>

          </>
        }



      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete user, Proceed ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Yes</Button>
          <Button onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>



    </div>
  )
}

export default UsersTable
