import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import '../css/home.scss'
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
import { selectGeneral, setEventRef } from '../store/general/generalSlice';
import { useAppDispatch } from '../store/hook';
import EventDataTab from './user/components/EventDataTab';
import imageBanner from '../public/images/ls-banner.jpg'
import imgeLogo from '../public/images/youth-logo.png'
import { saveToken, getToken, logUserOut, saveEventRef } from '../utils/LocalStorage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetEventWithRefClientMutation } from '../store/event/api';
import { Speaker, Schedule } from '../utils/AppInterface';
import moment from 'moment';

import ismLogo from '../public/images/ism-logo.jpeg'




const Home = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [getEventWithRef, { isLoading }] = useGetEventWithRefClientMutation();

    const [eventSpeakers, setEventSpeakers] = useState<Speaker[]>([]);
    const [scheduleData, setScheduleData] = useState<Schedule[]>([]);
    const [eventData, setEventData] = useState<any | null>([]);


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const event = queryParams.get("event")
        if(event !== '' && event !== null){
          saveEventRef(event)
          getEventData(event)
        }

        // dispatch(setEventRef(event))
        // if(getToken() === null){
        //   //  navigate('/')
        //   //  setIsAuthenticated(false)
        // }else{
        //   //  setIsAuthenticated(true)
        // }
    },[]);


   
    const goToRegister = () => {
        navigate('/auth/register')
    }

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth] = React.useState('sm');

    const [anchorElSm, setAnchorElSm] = React.useState(null);
    const openSm = Boolean(anchorElSm);
    const handleClickSm = (event: any) => {
        setAnchorElSm(event.currentTarget);
    };

    const handleCloseSm = () => {
        setAnchorElSm(null);
    };

    const processLogout = () => {
        navigate('/')
        logUserOut()
    }

    const processDashboard = () => {
        navigate('/user/dashboard')
    }

    const processSignUp = () => {
        navigate('/auth/register')
    }

    const processSignIn = () => {
        navigate('/auth/login')
    }

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openLoading, setOpenLoading] = React.useState(true);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };



  const getEventData = (eventRef: any) => {
  
    getEventWithRef(eventRef)
      .unwrap()
      .then((res) => {
        setEventSpeakers(res.speaker)
        setScheduleData(res.schedules)
        setEventData(res)
        handleCloseLoading()
       
      })
      .catch((e) => {
        handleCloseLoading()
        // NotifyAlert(e.error, 'error')
      });
  };
  



  return (

    <>
        {/* Nav Bar */}
        <div>
            <Navbar sticky="top" className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand>
                        <img src={ismLogo} className='img-fluid logo-img' alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>

                    {isAuthenticated ? <>
                            <div>
                                <Button className='btn-light'
                                    id="basic-button-user"
                                    aria-controls={openSm ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openSm ? 'true' : undefined}
                                    onClick={handleClickSm}
                                >
                                <AccountCircleIcon />
                                </Button> 
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElSm}
                                    open={openSm}
                                    onClose={handleCloseSm}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    PaperProps={{
                                        style: {
                                        width: '25ch',
                                        },
                                    }}
                                    >
                                <MenuItem onClick={() => {processDashboard(); handleCloseSm()}}>
                                    <div className="menu-item">
                                        <p className="menu-item-text">Dashboard</p>
                                    </div>
                                </MenuItem>   

                                <MenuItem onClick={() => {processLogout(); handleCloseSm()}}>
                                    <div className="menu-item">
                                        <p className="menu-item-text">Logout</p>
                                    </div>
                                </MenuItem>   

                                </Menu> 
                            </div>
                        
                        </> : <>      
                            <NavLink className="navbar-item" to="/auth/login">
                                Sign In
                            </NavLink>
                        </> }
                    </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>


        <div className='first-wrapper'>
            <div className='container'>
                <div className='row gx-4 gy-4'>
                    <div className='col-md-7 col-sm-12'>
                        <Card border="light" body>
                            <img src={eventData?.image} className='img-fluid' alt="" />
                        </Card>
                    </div>

                    <div className='col-md-5 col-sm-12'>
                        <Card border="light" body>
                            <h4 className='wrapper-title'>{ eventData?.title }</h4>
                            {/* <h6 className='registered-count'>5170 People are registered</h6> */}

                            <div className='event-date-wrapper'>
                                <div>
                                    <h6 className='event-title'>Start Time</h6>
                                    <p className='event-value'>{ moment(eventData?.eventStartDateTime).format('LLLL') }</p>
                                </div>

                                <div>
                                    <h6 className='event-title'>End Time</h6>
                                    <p className='event-value'>{ moment(eventData?.eventEndDateTime).format('LLLL') }</p>
                                </div>
                            </div>

                            {isAuthenticated ? <></> : <>
                                <button className='btn-block register-btn' onClick={handleClickOpen}>Register For the event</button>
                            </>}
                        </Card>
                    </div>
                </div>
            </div>
        </div>


        <div className='mt-3'>
            <EventDataTab eventSpeakers={eventSpeakers}  scheduleData={scheduleData} eventData={eventData} />
        </div>



        <Dialog fullWidth={fullWidth} open={open} onClose={handleClose}>
                {/* <DialogTitle>Select Category</DialogTitle> */}
                <DialogContent>
                    <div className="modal-btn-wrapper mt-4">
                        <div className="">
                        <button className='btn btn-success' onClick={processSignUp}>Sign Up</button>
                        </div>
                        <div>
                        <button className='btn btn-primary' onClick={processSignIn}>Sign In</button>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn btn-danger' onClick={() => {handleClose();}}>Cancel</button>
                </DialogActions>
        </Dialog>


        
        <Dialog fullWidth={fullWidth} open={openLoading} onClose={(event, reason) => {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
            handleCloseLoading()
        }
    }}>
            <DialogContent>
                <div className="d-flex justify-content-center mt-4">
                    <div>
                        <div className="spinner-border"></div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>




    </>


  )
}

export default Home





// SELECT `title`, `name`, `gender`, `phone`, `email`, `balance`, `state`, `country` FROM `users` INNER JOIN `user_infos` ON `users`.`id` = `user_infos`.`uid` INNER JOIN `users_wallet` ON `users`.`id` = `users_wallet`.`uid` WHERE `users`.`status` = 1 ORDER BY `users_wallet`.`balance` DESC

