import React, { useEffect } from 'react'
import EventsTable from '../components/EventsTable'
import CreateEventDialog from '../components/CreateEventDialog'



const Events = ()  => {

  useEffect(() => {
  
  
   }, []);
  
const [dialogOpen, setDialogOpen] = React.useState(false);
const [sendDialogRes, setSendDialogRes] = React.useState(false);


const handleClose = () => {
  setDialogOpen(false);
  setSendDialogRes(true)
  setTimeout(() => {
     setSendDialogRes(false)
   }, 5000)
};



  return (
    <div>
      <div className='d-flex mb-4'>
        <div>
          <button className='btn btn-danger' onClick={() => setDialogOpen(true)}>Create Event</button>
        </div>
      </div>

      <EventsTable sendDialogRes={sendDialogRes} />
      <CreateEventDialog dialogOpen={dialogOpen} handleClose={handleClose} />
    </div>
  )
}

export default Events

