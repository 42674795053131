import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { CreateEventValidation, UpdateEventValidation } from '../../../validations/EventValidation';
import { useNavigate } from 'react-router-dom';
import { useCreateEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss';
import { NotifyAlert } from '../../../utils/NotifyAlert';
import InfoIcon from '@mui/icons-material/Info';
import { useSendCreateEventDataMutation } from '../../../store/sendFile/api';
import axios from 'axios';
import { getToken } from '../../../utils/LocalStorage';



interface dialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
}

const CreateEventDialog: React.FC<dialogProps> = ({ dialogOpen, handleClose }) => {

  const [open, setOpen] = React.useState(dialogOpen);
  const navigate = useNavigate();
 //// const [createEvent, { isLoading }] = useSendCreateEventDataMutation();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');
  const [isLoading, setIsLoading] = React.useState(false);


  const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: {
      title: '',
      description: '',
      liveStreamLink: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      file: '',
      designationOfMinistry: ''
    },
    validationSchema: CreateEventValidation,
    onSubmit: () => initRequest(),
  });

   const initRequest = async () => {

    setIsLoading(true)

    const startDateTime = `${values.startDate}T${values.startTime}:00`;
    const endDateTime = `${values.endDate}T${values.endTime}:00`;

    const data = {
      title: values.title,
      eventStartDateTime: startDateTime,
      eventEndDateTime: endDateTime,
      description: values.description,
      liveStreamLink: values.liveStreamLink,
      designationOfMinistry: values.designationOfMinistry.split(',')
    };

    var bodyFormData = new FormData();
    bodyFormData.append('file', values.file);
    bodyFormData.append('title', values.title);
    bodyFormData.append('eventStartDateTime', startDateTime);
    bodyFormData.append('eventEndDateTime', endDateTime);
    bodyFormData.append('description', values.description);
    bodyFormData.append('liveStreamLink', values.liveStreamLink);
    bodyFormData.append('designationOfMinistry', values.designationOfMinistry);

    const config = {
      headers: {
        Authorization: getToken(),
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const response = await axios.post('https://ministerial-conference.onrender.com/api/v1/events/create', bodyFormData, config);

      if (response.status === 200) {
          setIsLoading(false)
          NotifyAlert('Event added successfully', 'success')
          handleClose()
          setFieldValue('title', '');
          setFieldValue('description', '');
          setFieldValue('liveStreamLink', '');
          setFieldValue('startDate', '');
          setFieldValue('startTime', '');
          setFieldValue('endDate', '');
          setFieldValue('endTime', '');
          setFieldValue('file', '');
          setFieldValue('designationOfMinistry', '');
      
      }else{
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('An error occurred:', error);
    }


    // createEvent(bodyFormData)
    //   .unwrap()
    //   .then((res) => {
    //      NotifyAlert('Event added successfully', 'success')
    //      handleClose()
        
    //   })
    //   .catch((e) => {
    //     // NotifyAlert(e.error, 'error')
    //   });


  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setFieldValue(name, value);
  };

  const formatTime = (value: any) => {
    return `${value.$H.toString().padStart(2, '0')}:${value.$m.toString().padStart(2, '0')}`;
  };


  const handleStartTimeChange = (value: any, context: any) => {
    const setStartTime = formatTime(value);
    setFieldValue('startTime', setStartTime);
  };

  const handleEndTimeChange = (value: any, context: any) => {
    const setEndTime = formatTime(value);
    setFieldValue('endTime', setEndTime);
  };

  
  const handleFileUpload = (data: any) => {
    // console.log(data.target.files[0], 'file')
     setFieldValue('file', data.target.files[0])
 }


  return (
    <div>
      <React.Fragment>

        <Dialog fullWidth={fullWidth} open={dialogOpen} onClose={handleClose}>
          <DialogTitle>Create Event</DialogTitle>
          <DialogContent>
            <div className="form-wrapper">
              <div className="mt-4">
                <Input
                  type="text"
                  label="Title"
                  value={values.title}
                  name="title"
                  error={Boolean(errors.title)}
                  errorText={errors.title}
                  onChange={handleChange}
                  placeholder="Please enter title"
                />
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Description"
                  value={values.description}
                  name="description"
                  error={Boolean(errors.description)}
                  errorText={errors.description}
                  onChange={handleChange}
                  placeholder="Please enter description"
                />
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Live Stream Link"
                  value={values.liveStreamLink}
                  name="liveStreamLink"
                  error={Boolean(errors.liveStreamLink)}
                  errorText={errors.liveStreamLink}
                  onChange={handleChange}
                  placeholder="Please enter live stream link"
                />
              </div>

              <div className="mt-4">
                <div className="date-picker-wrapper">
                  <div>
                    <Input
                      type="date"
                      label="Start Date"
                      value={values.startDate}
                      name="startDate"
                      error={Boolean(errors.startDate)}
                      errorText={errors.startDate}
                      onChange={handleChange}
                      placeholder="Please enter start date"
                    />
                  </div>

                  <div>
                    <InputDatePicker
                      label="Start Time"
                      value={values.startTime}
                      onChange={handleStartTimeChange}
                      error={Boolean(errors.startTime)}
                      errorText={errors.startTime}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="date-picker-wrapper">
                  <div>
                    <Input
                      type="date"
                      label="End Date"
                      value={values.endDate}
                      name="endDate"
                      error={Boolean(errors.endDate)}
                      errorText={errors.endDate}
                      onChange={handleChange}
                      placeholder="Please enter end date"
                    />
                  </div>

                  <div>
                    <InputDatePicker
                      label="End Time"
                      value={values.endTime}
                      onChange={handleEndTimeChange}
                      error={Boolean(errors.endTime)}
                      errorText={errors.endTime}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Input
                  type="text"
                  label="Event Designation"
                  value={values.designationOfMinistry}
                  name="designationOfMinistry"
                  error={Boolean(errors.designationOfMinistry)}
                  errorText={errors.designationOfMinistry}
                  onChange={handleChange}
                  placeholder="Please enter event designation"
                />
                <small>separate each designation with a ','</small>
              </div>


              <div className='mt-4'>
                <label htmlFor="Upload Photo" className='input-label'>Upload Photo</label>
                  <input
                    type='file'
                    id="file"
                    name="file"
                    placeholder="upload file"
                    onChange={handleFileUpload}
                    className='input-text'
                  />

                  {Boolean(errors.file && errors.file)  && <><p className="error"> <InfoIcon className='error-icon' /> { errors.file }</p></> }
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              {!isLoading ? (
                <span>Submit</span>
              ) : (
                <span>
                  <div className="spinner-border text-dark spinner-border-sm"></div>
                </span>
              )}
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    </div>
  );
};

export default CreateEventDialog;