import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import { CreateEventValidation, RegisterToEventValidation } from '../../../validations/EventValidation';
import { useNavigate } from 'react-router-dom';
import { useCreateEventMutation } from '../../../store/event/api';
import InputDatePicker from '../../../components/form/InputDatePicker';
import '../../../css/create-event-dialog.scss';
import { NotifyAlert } from '../../../utils/NotifyAlert';
import DymanicSelect from '../../../components/form/DymanicSelect';
import { useRegisterUserToEventMutation } from '../../../store/user/api';
import { useGetEventWithRefMutation } from '../../../store/event/api';
import { selectGeneral, setEventRef } from '../../../store/general/generalSlice';
import { useSelector } from 'react-redux';
import { getEventRef, removeEventRef } from '../../../utils/LocalStorage';




interface dialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  handleSubmittedClose: (state: boolean) => void;
}


interface Gender {
    id: string,
    name: string,
    value: string
  }


  interface eventCategory {
    id: string,
    designationOfMinistry: string,
  }

const SelectDesignation: React.FC<dialogProps> = ({ dialogOpen, handleClose, handleSubmittedClose }) => {



  useEffect(() => {
    if(getEventRef() !== null && getEventRef() !== 'null'){
      let eventRef = getEventRef();
      setEventRefere(eventRef)         
    }
    getEventData()

},[]);


    
  const [open, setOpen] = React.useState(dialogOpen);
  const [submitted, setSubmitted] = React.useState(false);
  const [eventRefere, setEventRefere] = useState<any>(null);
  const navigate = useNavigate();
  const [registerToEvent, { isLoading }] = useRegisterUserToEventMutation();
  const [getEventWithRef] = useGetEventWithRefMutation();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');
  const [selectedDesgination, setSelectedDesgination] = useState<eventCategory | null>(null);
  const [selectedDesginationId, setSelectedDesginationId] = useState('');
  const [curEventData, setCurEventData] = useState<eventCategory[]>([]);
  
  const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: {
        designationOfMinistry: '',
    },
    validationSchema: RegisterToEventValidation,
    onSubmit: () => initRequest(),
  });

  const initRequest = () => {
    let eventRef = getEventRef();

    let data = {
      designationOfMinistry: values.designationOfMinistry,
      eventRef: eventRef
    }
    registerToEvent(data)
      .unwrap()
      .then((res) => {
         NotifyAlert('Event added successfully', 'success')
         handleClose()
          removeEventRef()
        setSubmitted(true)
        handleSubmittedClose(true)
      })
      .catch((e) => {
         NotifyAlert(e.data.message, 'error')

         if(e.data.message === "User already registered for this Event"){
          handleClose()
          removeEventRef()
          setSubmitted(true)
          handleSubmittedClose(true)
         }

      });
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setFieldValue(name, value);
  };



  const getEventData = () => {
    let eventRef = getEventRef();

    getEventWithRef(eventRef)
      .unwrap()
      .then((res) => {
        if(res.userCategories.length > 0){
          setCurEventData(res.userCategories)

        }else{
          initRequest()
        }
      })
      .catch((e) => {
        // NotifyAlert(e.error, 'error')
      });
  };
  


  
//  const onTrigger = (event: any) => {
//     // Call the parent callback function 
//     this.props.parentCallback(event.target.myname.value);
//     event.preventDefault();
// }

  
  

  return (
        <div>
            <React.Fragment>

              <Dialog fullWidth={fullWidth} open={dialogOpen} onClose={handleClose}>
                  <DialogTitle>Select Category</DialogTitle>
                  <DialogContent>
                      <div className="form-wrapper">
                          <div className="mt-4">
                              <DymanicSelect
                              items={curEventData}
                              label="Select Category"
                              value={selectedDesginationId}
                              labelExtractor={({ designationOfMinistry }) => designationOfMinistry}
                              valueExtractor={({ id }) => id}
                              onValueChange={(value, selectedValue) => {

                                  setSelectedDesgination(selectedValue);
                                  setSelectedDesginationId(value);
                              //  console.log(value, 'hehe')
                                  setFieldValue('designationOfMinistry', selectedValue.designationOfMinistry)
                                  
                              }}
                              name="designationOfMinistry"
                              error={Boolean(errors.designationOfMinistry)}
                              errorText={errors.designationOfMinistry}
                              placeholder="Select Category"
                              />
                          </div>
                      </div>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={() => {handleClose();}}>Cancel</Button>
                  <Button
                      onClick={() => {
                      handleSubmit();
                      }}
                  >
                      {!isLoading ? (
                      <span>Submit</span>
                      ) : (
                      <span>
                          <div className="spinner-border text-dark spinner-border-sm"></div>
                      </span>
                      )}
                  </Button>
                  </DialogActions>
              </Dialog>
            </React.Fragment>
        </div>
  )
}

export default SelectDesignation


