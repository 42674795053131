import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGetEventWithRefMutation } from '../../../store/event/api';
import { Schedule, Speaker, Workshop, Category } from '../../../utils/AppInterface';
import Card from 'react-bootstrap/Card';
import ReactPlayer from 'react-player'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Avatar from '@mui/material/Avatar';
import '../../../css/view-event.scss'
import moment from 'moment';






const ViewEvent = () => {

    const { event } = useParams();
    const [getEventWithRef] = useGetEventWithRefMutation();
    const [curEventData, setCurEventData] = useState<any>();
    const [eventSpeakers, setEventSpeakers] = useState<Speaker[]>([]);
    const [scheduleData, setScheduleData] = useState<Schedule[]>([]);
    const [workshopData, setWorkshopData] = useState<Workshop[]>([]);
    const [eventData, setEventData] = useState<any | null>([]);
    const [categoryData, setCategoryData] = useState<Category[]>([]);



    useEffect(() => {
        getEventData(event)
          
    },[]);


    const getEventData = (eventRef: any) => {
        getEventWithRef(eventRef)
          .unwrap()
          .then((res) => {
            setEventSpeakers(res.speaker)
            setScheduleData(res.schedules)
            setWorkshopData(res.workshops)
            setEventData(res)
            setCategoryData(res.userCategories)
            console.log(res, 'event data')
           
          })
          .catch((e) => {
            // NotifyAlert(e.error, 'error')
          });
      };
      


  return (
    <div>
        <div>
            <div className='container'>
                <div className='row gx-4 gy-4'>
                    <div className='col-md-1 col-sm-12'></div>
                    <div className='col-md-10 col-sm-12'>
                        <Card border="light" body className='stream-body'>
                            <ReactPlayer
                                url={'https://www.youtube.com/watch?v=-9xcIhy1WbA&t=936s'}
                                height="300px"
                                width='100%'
                                controls={true}
                                loop={true}
                                light={true}
                                volume={1}
                                playing={true}
                                preload="auto"
                                autoPlay
                                id="videoplayer"
                            />
                        </Card>
                    </div>
                </div>

                <div className='row gx-4 gy-4'>
                    <div className='col-md-3 col-sm-12'></div>
                    <div className='col-md-6 col-sm-12'>
                        <Card border="light" body className='stream-body'>
                            <div>
                                <p className='event-title-text'>Title</p>
                                <h6 className='event-title-value'>{ eventData?.title }</h6>
                            </div>

                            <div className='mt-3'>
                                <p className='event-title-text'>Description</p>
                                <h6 className='event-title-value'>{ eventData?.description }</h6>
                            </div>

                            <div className='view-event-date-wrapper'>
                                <div>
                                    <p>Start Date</p>
                                    <h6 className='font-weight-bold'>{ moment(eventData?.eventStartDateTime).format('LLLL') }</h6>
                                </div>
                                <div>
                                    <p>End Date</p>
                                    <h6 className='font-weight-bold'>{ moment(eventData?.eventEndDateTime).format('LLLL') }</h6>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>


            <div className='container-fluid'>
                <div className='row gx-4 gy-4'>
                    <div className='col-12 col-md-12'>

                        <Tabs
                            defaultActiveKey="home"
                            id="justify-tab-example"
                            className="mb-3"
                            justify
                        >
                            <Tab eventKey="home" title="Workshop">
                                <table className="table table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Designation Of Ministry</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {workshopData && workshopData.map((item, index) => (
                                        <tr key={index}>    
                                            <td>{ index + 1}</td>     
                                            <td> { item.name } </td>
                                            <td> { item.designationOfMinistry } </td>   
                                            <td>{ item.description }</td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                </table>
                            </Tab>
                       
                        
                            <Tab eventKey="longer-tab" title="Schedule">
                                <table className="table table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>S/N</th>
                                            <th>Activity Name</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {scheduleData && scheduleData.map((item, index) => (
                                        <tr key={index}>    
                                            <td>{ index + 1}</td>     
                                            <td> { item.activityName } </td>
                                            <td> { moment(item?.startTime).format('LLLL') } </td>   
                                            <td> { moment(item?.endTime).format('LLLL') } </td>                                
                                            <td>{ item.description }</td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="speakers" title="Speakers">
                                <table className="table table-hover table-bordered">
                                    <thead className="table-dark">
                                    <tr>
                                        <th>Name</th>
                                        <th>Bio</th>
                                        <th>Portfolio</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {eventSpeakers && eventSpeakers.map((item, index) => (
                                        <tr key={index}>                                            
                                            <td>
                                                <div className="speaker-name-column">
                                                <div>
                                                    <Avatar
                                                            alt={item.name}
                                                            src={item.photo}
                                                            sx={{ width: 56, height: 56 }}
                                                            />
                                                </div>
                                                <div>{item.name} </div>
                                                </div>
                                            
                                            </td>
                                            <td>{ item.bio }</td>
                                            <td>{ item.portfolio }</td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                </table>

                            </Tab>
                            <Tab eventKey="booths" title="Category">
                                <table className="table table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>S/N</th>
                                            <th>Designation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {categoryData && categoryData.map((item, index) => (
                                        <tr key={index}>    
                                            <td>{ index + 1}</td>                                        
                                            <td>{ item.designationOfMinistry }</td>
                                        </tr>
                                    ))} 
                                    </tbody>
                                </table>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>




        </div>
    </div>
  )
}

export default ViewEvent


