
import React, { useState } from 'react'
import Container from '@mui/material/Container';
import './login.scss'
import { useFormik } from 'formik';
// import { displayNotify } from '../utilities/Notify'
import { useNavigate } from 'react-router-dom';
import { LoginValidation } from '../../../validations/AuthValidation';
import Input from '../../../components/form/Input';
import { NotifyAlert } from '../../../utils/NotifyAlert';
import { useAdminLoginMutation } from '../../../store/admin/api';
import { saveAdminData, saveToken } from '../../../utils/LocalStorage';







const  AdminLogin = () => {
  const [loginAdmin, { isLoading }] = useAdminLoginMutation();
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
  
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClose = () => setShow(false);
  
    const handleShow = (data: any) => {
        setShow(true);
        setFormData(
            {
                email: data.email,
                password: data.password
            }
        )
    } 
  
  
   const handleShowPassword = () => {
      setShowPassword(!showPassword)
    }
  
    
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      setFieldValue(name, value)
  }
  
  
  
  const initRequest = () => {

    loginAdmin(values)
        .unwrap()
        .then((res) => {
          NotifyAlert('Login successful', 'success')
          saveToken(res.token);
          saveAdminData(res.user)
          navigate('/admin/dashboard')
        })
        .catch((e) => {
          if(e.data !== null){
            NotifyAlert(e.data.message, 'error')
          }else{
            NotifyAlert('No server response, Server error', 'error')
          }
        });

  }
  
  
  const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: LoginValidation,
    onSubmit: () => initRequest()
  })


  return (
    <div>
      <Container maxWidth="lg" className='login-content-wrapper'>
        <div className='row'>
            <div className='col-md-4 col-12'></div>
          <div className='col-md-4 col-sm-12'>
            <div className='login-wrapper'>
            
              <p className='subtitle text-center'>Admin login.</p>

              {/* <div className="alert alert-primary" role="alert">
                Email <b>admin@gmail.com</b>, Password <b>admin1234</b>
              </div> */}


              <div className='form-wrapper'>
                  <div className='mt-4'>
                    <Input
                      type="email"
                      label="Email"
                      value={values.email}
                      name="email"
                      error={Boolean(errors.email)}
                      errorText={errors.email}
                      onChange={handleChange}
                      placeholder="Please enter your email"
                    />
                  </div>

                  <div className='mt-4'>
                    <Input
                      type="password"
                      label="Password"
                      value={values.password}
                      name="password"
                      error={Boolean(errors.password)}
                      errorText={errors.password}
                      onChange={handleChange}
                      placeholder="Please enter your password"
                    />
                  </div>
                     
                  <div className="form-group mt-4">
                      <button disabled={isLoading} onClick={() => {handleSubmit()}} className='btn-block login-btn'>
                        { !isLoading ? <><span>Login</span></>  : <><span><div className="spinner-border text-light spinner-border-sm"></div></span></> }
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        
      </Container>
    </div>
  )
}

export default AdminLogin