import React from 'react'
import SpeakerTable from '../components/SpeakerTable';
import CreateSponsor from '../components/CreateSponsor';
import SponsorTable from '../components/SponsorTable';



const Sponsor = () => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClose = () => {
      setDialogOpen(false);
    };


  return (
    <div>
      <div className='d-flex mb-4'>
        <div>
          <button className='btn btn-danger' onClick={() => setDialogOpen(true)}>Create Sponsor</button>
        </div>
      </div>

      <SponsorTable />
      <CreateSponsor dialogOpen={dialogOpen} handleClose={handleClose} />
    </div>
  )
}

export default Sponsor


