import React, { useEffect, useState } from 'react'
import { selectGeneral, setEventRef } from '../../store/general/generalSlice';
import { useAppDispatch } from '../../store/hook';
import { useSelector } from 'react-redux';
import SelectDesignation from './components/SelectDesignation';
import Table from 'react-bootstrap/Table';
import { useGetUserEventMutation } from '../../store/user/api';
import PageLoader from '../../components/loader/PageLoader';
import { useNavigate } from 'react-router-dom';
import { getEventRef, removeEventRef } from '../../utils/LocalStorage';
import moment from 'moment';


interface Event {
    id: number;
    name: string;
    title: string;
    eventStartDateTime: string;
    eventEndDateTime: string;
    description: string;
    liveStreamLink: string;
    userEventCategory: string;
}





const EventDashboard = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if(getEventRef() !== null && getEventRef() !== 'null'){
          let eventRef = getEventRef();
             setDialogOpen(true)
             setCurEventRef(eventRef)             
        }

        getCurrentUserEvent()
    
    },[]);

    const { eventRef } = useSelector((state: any)  => { return state.general});
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [curEventRef, setCurEventRef] = useState<any>()
    const [userRegisteredEvents, setUserRegisteredEvents] = useState<Event[]>([])


    const handleClose = () => {
        setDialogOpen(false);
        removeEventRef()

    };

    const handleSubmittedClose = (state: boolean) => {
        if(state){
          console.log('we passed')
          getCurrentUserEvent()
        }
    }

    const [getUserEvent, { isLoading }] = useGetUserEventMutation();
    
    
  const getCurrentUserEvent = () => {
    let data: any = []
    getUserEvent(data)
    .unwrap()
    .then((res: any) => {
      setUserRegisteredEvents(res)
    // NotifyAlert(res.responseMessage, 'success')
    //  console.log(res, 'res data')

    })
    .catch((e) => {
    // NotifyAlert(e.error, 'error')
    });

  }


  //const [userEvent, setUserEvent] = useState<Event[]>(initState)

    const gotToEvent = (data: any) => {
        navigate(`/user/event/${data.eventRef}`)
    }


  return (
    <div>

          <div className='container-fluid mt-3'>
            <div className='row'>
                    { isLoading ? <> <PageLoader /> </> : <>
                      <div className='col-12 col-md-2'></div>
                      <div className='col-12 col-md-8'>
                        <h6 className='text-center'>Registered Events</h6>

                        <table className="table table-hover table-bordered">
                          <thead className="table-dark">
                            <tr>
                                <th>S/N</th>
                                <th>Title</th>
                               
                                <th>Event Date</th>
                                <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                              {userRegisteredEvents.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{moment(item.eventStartDateTime).format('LLLL')}</td>
                                        <td>
                                            <button className='join-btn btn btn-success' onClick={() => {gotToEvent(item)}}>Join</button>
                                        </td>
                                
                                    </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>


                    </>}
            </div>
          </div>


        <SelectDesignation dialogOpen={dialogOpen} handleClose={handleClose} handleSubmittedClose={handleSubmittedClose} />


    </div>
  )
}

export default EventDashboard
