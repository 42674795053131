import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import NotFound from './pages/NotFound';

/// Admin ///
import AdminLogin from './pages/admin/auth/AdminLogin';
import AdminLayer from './pages/admin/layout/AdminLayer';
import Dashboard from './pages/admin/pages/Dashboard';
import Description from './pages/admin/pages/Description';
import Workshops from './pages/admin/pages/Workshops';
import Users from './pages/admin/pages/Users';
import Speaker from './pages/admin/pages/Speaker';
import Schedule from './pages/admin/pages/Schedule';
import Events from './pages/admin/pages/Events';
import Sponsor from './pages/admin/pages/Sponsor';
import ViewEvent from './pages/admin/pages/ViewEvent';



//// User ///
import EventPage from './pages/user/EventPage';
import UserLayer from './pages/user/layout/UserLayer';
import EventDashboard from './pages/user/EventDashboard';





function App() {
  return (
 
    <>
      <Routes>
        <Route path='/'>
          <Route index element={<Login />} />
          <Route path='home' element={<Home />} />
        </Route>

     


        {/* User */}
        <Route path="/auth">
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot" element={<ForgotPassword />} />
        </Route>

        <Route path="/user" element={ <UserLayer /> }>
          <Route index element={<EventDashboard />} />
          <Route path="dashboard" element={<EventDashboard />} />
          <Route path="event/:eventRef" element={<EventPage />} />
        </Route>



        {/* Admin */}
        <Route path="/auth/admin">
          <Route index element={<AdminLogin />} />
          <Route path="login" element={<AdminLogin />} />
        </Route>

        <Route path="/admin" element={ <AdminLayer />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="events" element={<Events />} />
          <Route path="sponsors" element={<Sponsor />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="speaker" element={<Speaker />} />
          <Route path="users" element={<Users />} />
          <Route path="workshops" element={<Workshops />} />
          <Route path="view-event/:event" element={<ViewEvent />} />


        </Route>

       


        <Route path='*' element={<NotFound />} />
      </Routes>
    </>

  );
}

export default App;
