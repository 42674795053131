import React from 'react'
import SpeakerTable from '../components/SpeakerTable';
import CreateSpeakerDialog from '../components/CreateSpeakerDialog';




const Speaker = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentSpeakerData, setCurrentSpeakerData] = React.useState<any>(null);
  const [sendDialogRes, setSendDialogRes] = React.useState(false);



  

  const handleClose = () => {
    setDialogOpen(false);
    setSendDialogRes(true)
    setTimeout(() => {
       setSendDialogRes(false)
     }, 5000)
  };

  return (
    <div>
      <div className='d-flex mb-4'>
        <div>
          <button className='btn btn-danger' onClick={() => setDialogOpen(true)}>Create Speaker</button>
        </div>
      </div>

      <SpeakerTable sendDialogRes={sendDialogRes}/>
      <CreateSpeakerDialog dialogOpen={dialogOpen} handleClose={handleClose} currentSpeakerData={currentSpeakerData} />
    </div>
  )
}

export default Speaker

