
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/register.scss'
import Input from '../../components/form/Input';
import { useFormik } from 'formik';
import { RegisterValidation } from "./../../validations/AuthValidation";
import { NavLink } from "react-router-dom";
import { Card } from 'react-bootstrap';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DymanicSelect from '../../components/form/DymanicSelect';
import { useRegisterMutation, useLoginMutation } from '../../store/user/api';
import { NotifyAlert } from '../../utils/NotifyAlert';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { saveToken, saveUserData, getUserType, getToken, saveEventRef } from '../../utils/LocalStorage';
import imgeLogo from '../../public/images/youth-logo.png'
import countries from '../../public/file/conuntries.json'
import imageBanner from '../../public/images/ls-banner.jpg'
import ismLogo from '../../public/images/ism-logo.jpeg'




const Register = ()  => {

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const event = queryParams.get("event")
    if(event !== '' && event !== null){
      saveEventRef(event)
    }
  },[]);

  const navigate = useNavigate()
  const [registerUser, { isLoading }] = useRegisterMutation();
  const [loginUser] = useLoginMutation();


  const [selectedGender, setSelectedGender] = useState<Gender | null>(null);
  const [selectedGenderId, setSelectedGenderId] = useState('');

  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup | null>(null);
  const [selectedAgeGroupId, setSelectedAgeGroupId] = useState('');
  
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCountryId, setSelectedCountryId] = useState('');

  
  const [selectedTitle, setSelectedTitle] = useState<Title | null>(null);
  const [selectedTitleId, setSelectedTitleId] = useState('');

    const [formData, setFormData] = useState({
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      otherName: "",
      gender: '',
      ageGroup: '',
      countryOfResidence: "",
      nameOfMinistry: ""
    });


    const { values, errors, handleSubmit, setFieldValue, touched } = useFormik({
      initialValues: {  
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      otherName: "",
      gender: '',
      ageGroup: '',
      countryOfResidence: "",
      nameOfMinistry: ""

    },
      validationSchema: RegisterValidation,
      onSubmit: () => initRequest()
    })


    const initRequest = () => {    
      registerUser(values)
      .unwrap()
      .then((res) => {
        NotifyAlert(res.responseMessage, 'success')
        loginUserInit()
      })
      .catch((e) => {
        if(e.data && e.data !== null){
          NotifyAlert(e.data.message, 'error')
        }else{
          NotifyAlert('No server response, Server error', 'error')
        }
      });

    }



    const loginUserInit = () => {

      let data = {
        email: values.email,
        password: values.password
      }

      loginUser(data)
      .unwrap()
      .then((res) => {
        NotifyAlert('Login successful', 'success')
        saveToken(res.token);
        saveUserData(res.user)
        navigate('/user/dashboard')
      })
      .catch((e) => {
        if(e.data !== null){
          NotifyAlert(e.data.message, 'error')
        }else{
          NotifyAlert('No server response, Server error', 'error')
        }
      });


    }



const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const name = event.target.name;
  const value = event.target.value;
  setFieldValue(name, value)
}

interface Gender {
  id: string,
  name: string,
  value: string
}

interface Title {
  id: string,
  name: string,
  value: string
}


interface Country {
  code: string,
  name: string,
}


const GenderData = [
  {
    id: '1', name: "Male", value: "Male"
  },
  {
    id: '2', name: "Female", value: "Female"
  }
];


interface AgeGroup {
  id: string,
  name: string,
  value: string
}


const AgeGroupData = [
  {
    id: '1', name: "20-40yrs", value: "20-40yrs"
  },
  {
    id: '2', name: "41-60yrs", value: "41-60yrs"
  },
  {
    id: '3', name: "61 and Above", value: "61 and Above"
  }
];



const TitleData = [
  {
    id: '1', name: "Pastor", value: "Pastor"
  },
  {
    id: '2', name: "Prophet", value: "Prophet"
  },
  {
    id: '3', name: "Prophetess", value: "Prophetess"
  },
  {
    id: '4', name: "Apostle", value: "Apostle"
  },
  {
    id: '5', name: "Deacon", value: "Deacon"
  },
  {
    id: '6', name: "Deaconess", value: "Deaconess"
  },
  {
    id: '7', name: "Evangelist", value: "Evangelist"
  },
  {
    id: '8', name: "Youth Pastor", value: "Youth Pastor"
  },
  {
    id: '9', name: "Minister", value: "Minister"
  },
  {
    id: '10', name: "Youth Minister", value: "Youth Minister"
  },
  {
    id: '11', name: "Others", value: "Others"
  }
];



  return (
    <>

        {/* Nav Bar */}
        <div>
            <Navbar className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand>
                        <NavLink className="navbar-item" to="/">
                            <img src={ismLogo} className='img-fluid logo-img' alt="" />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <NavLink
                        className="navbar-item"
                            to="/auth/login"
                        >
                            Sign In
                        </NavLink>
                    </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>


      <div className='container-fluid'>
          <Row className='gx-5 gy-5 mt-5'>
              <Col xs={12} md={8}>
                <h3 className='basic-info'>BASIC INFORMATION</h3>

                <div className='form-wrapper'>
                  <div className='row'>
                      <div className='col-md-6 col-12'>
                        {/* <Input
                          type="text"
                          label="Title"
                          value={values.title}
                          name="title"
                          error={Boolean(errors.title)}
                          errorText={errors.title}
                          onChange={handleChange}
                          placeholder="Please enter your title E.g Mr, Mrs"
                        /> */}

                          <DymanicSelect
                            items={TitleData}
                            label="Title"
                            value={selectedTitleId}
                            labelExtractor={({ name }) => name}
                            valueExtractor={({ id }) => id}
                            onValueChange={(value, selectedValue) => {
                              setSelectedTitle(selectedValue);
                              setSelectedTitleId(value);
                              setFieldValue('title', selectedValue.value)
                              
                            }}
                            name="title"
                            error={Boolean(errors.title)}
                            errorText={errors.title}
                            placeholder="Select Title"
                          />



                      </div>
                      <div className='col-md-6 col-sm-12'>  
                          <Input
                            type="text"
                            label="First Name"
                            value={values.firstName}
                            name="firstName"
                            error={Boolean(errors.firstName)}
                            errorText={errors.firstName}
                            onChange={handleChange}
                            placeholder="Please enter your first name"
                          />
                    </div>
                  </div>

                  <div className='row mt-4'>
                    <div className='col-md-6 col-sm-12'>  
                        <Input
                          type="text"
                          label="Last Name"
                          value={values.lastName}
                          name="lastName"
                          error={Boolean(errors.lastName)}
                          errorText={errors.lastName}
                          onChange={handleChange}
                          placeholder="Please enter your last name"
                        />
                    </div>

                    <div className='col-md-6 col-12'>
                        <Input
                          type="text"
                          label="Other Name"
                          value={values.otherName}
                          name="otherName"
                          error={Boolean(errors.otherName)}
                          errorText={errors.otherName}
                          onChange={handleChange}
                          placeholder="Please enter other name"
                        />
                    </div>

                  </div>

                    <div className='row mt-4'>
                      <div className='col-md-6 col-12'>
                        <Input
                          type="text"
                          label="Phone"
                          value={values.phoneNumber}
                          name="phoneNumber"
                          error={Boolean(errors.phoneNumber)}
                          errorText={errors.phoneNumber}
                          onChange={handleChange}
                          placeholder="Please enter your phone"
                        />
                      </div>


                      <div className='col-md-6 col-12'>
                        <DymanicSelect
                          items={GenderData}
                          label="Gender"
                          value={selectedGenderId}
                          labelExtractor={({ name }) => name}
                          valueExtractor={({ id }) => id}
                          onValueChange={(value, selectedValue) => {
                            setSelectedGender(selectedValue);
                            setSelectedGenderId(value);
                            setFieldValue('gender', selectedValue.value)
                            
                          }}
                          name="gender"
                          error={Boolean(errors.gender)}
                          errorText={errors.gender}
                          placeholder="Select Gender"
                          />
                      </div>




                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-6 col-12'>
                          <DymanicSelect
                              items={AgeGroupData}
                              label="Age Group"
                              value={selectedAgeGroupId}
                              labelExtractor={({ name }) => name}
                              valueExtractor={({ id }) => id}
                              onValueChange={(value, selectedValue) => {
                                setSelectedAgeGroup(selectedValue);
                                setSelectedAgeGroupId(value);
                                setFieldValue('ageGroup', selectedValue.value)
                                
                              }}
                              name="ageGroup"
                              error={Boolean(errors.ageGroup)}
                              errorText={errors.ageGroup}
                              placeholder="Select Age Group"
                              />
                        </div>
                        <div className='col-md-6 col-12'> 
                          <Input
                            type="email"
                            label="Email"
                            value={values.email}
                            name="email"
                            error={Boolean(errors.email)}
                            errorText={errors.email}
                            onChange={handleChange}
                            placeholder="Please enter your email"
                          />
                        </div>
                    </div>

                    <div className='row mt-4'>
                      <div className='col-md-6 col-12'>
                        <Input
                          type="password"
                          label="Password"
                          value={values.password}
                          name="password"
                          error={Boolean(errors.password)}
                          errorText={errors.password}
                          onChange={handleChange}
                          placeholder="Please enter your password"
                        />
                      </div>

                      <div className='col-md-6 col-12'>
                        <Input
                          type="password"
                          label="Confirm Password"
                          value={values.confirmPassword}
                          name="confirmPassword"
                          error={Boolean(errors.confirmPassword)}
                          errorText={errors.confirmPassword}
                          onChange={handleChange}
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>

                    <div className='row mt-4'>
                      <div className='col-md-6 col-12'>

                          <DymanicSelect
                              items={countries}
                              label="Country"
                              value={selectedCountryId}
                              labelExtractor={({ name }) => name}
                              valueExtractor={({ code }) => code}
                              onValueChange={(value, selectedValue) => {
                                setSelectedCountry(selectedValue);
                                setSelectedCountryId(value);
                                setFieldValue('countryOfResidence', selectedValue.name)
                                
                              }}
                              name="countryOfResidence"
                              error={Boolean(errors.countryOfResidence)}
                              errorText={errors.countryOfResidence}
                              placeholder="Select your country"
                              />


                      </div>

                      <div className='col-md-6 col-12'>
                        <Input
                            type="text"
                            label="Ministry"
                            value={values.nameOfMinistry}
                            name="nameOfMinistry"
                            error={Boolean(errors.nameOfMinistry)}
                            errorText={errors.nameOfMinistry}
                            onChange={handleChange}
                            placeholder="Please enter the name of your ministry"
                          />
                      </div>
                    </div>
                </div>
              </Col>


              <Col xs={12} md={4}>
                <Card body>
                  <div className='text-center'>
                    <img src={ismLogo} className='img-fluid company-logo' alt="" />
                  </div>

                  {/* <div className='logo-company-wrapper'>
                    <div>
                      <h6 className='text-center ignite-text'>Ignite! | 2023 MinistrySmart Conference</h6>
                      <p className='text-center host-text'>Sep 20, 3:30 PM <ArrowForwardIcon /> Sep 22, 12:00 AM</p>
                    </div>
                  </div> */}
                </Card>

                <Card body className='mt-4 reg-summary-wrapper shadow'>
                  <h4 className='reg-summary-text'>REGISTRATION SUMMARY</h4>
                  <p className='mini-text'>Ministry Partner All-Access Pass (×1)</p>
                  <hr />

                  <div className=''>
                    <button disabled={isLoading} onClick={() => {handleSubmit()}} className='btn-block register-btn'>
                      { !isLoading ? <><span>Register</span></>  : <><span><div className="spinner-border text-light spinner-border-sm"></div></span></> }
                    </button>
                  </div>

                  <p className='terms-text'>
                    By clicking "Register" you are creating a ISM Programs <br />
                    Events account and agree to the ISM Programs Acceptable <br />

                        <NavLink className="navbar-item-register" to="/auth/register">
                            Use Policy
                        </NavLink>,

                        <NavLink className="navbar-item-register" to="/auth/register">
                            Global Platform Terms 
                        </NavLink> 
                          and
                        <NavLink className="navbar-item-register" to="/auth/register">
                             the Privacy Policy.
                        </NavLink>
                  </p>
                </Card>

              </Col>
          </Row>
      </div>
    </>
  )
}

export default Register

