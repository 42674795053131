import { reactLocalStorage } from 'reactjs-localstorage'

export const saveToken = (token: string) => {
  reactLocalStorage.set('@netshift_conference_token', token)
}

export const saveEventRef = (ref: any) => {
  reactLocalStorage.set('@netshift_conference_ref', ref)
}

export const getEventRef = () => {
  const ref = reactLocalStorage.get('@netshift_conference_ref')
  return ref ? ref : null
}

export const removeEventRef = () => {
  reactLocalStorage.remove('@netshift_conference_ref')
}

export const saveUserData = (data: any) => {
  reactLocalStorage.set('@netshift_conference_user', JSON.stringify(data))
}

export const saveAdminData = (data: any) => {
  reactLocalStorage.set('@netshift_conference_admin', JSON.stringify(data))
}

export const getToken = () => {
  const token = reactLocalStorage.get('@netshift_conference_token')
  return token ? token : null
}

export const getUserData = () => {
  const storedUser: any = reactLocalStorage.get('@netshift_conference_user')
  const jsonStored = storedUser ? JSON.parse(storedUser) : null
  return jsonStored
}

export const getUserType = () => {
  const userData = reactLocalStorage.get('@netshift_conference_user')
  const adminData = reactLocalStorage.get('@netshift_conference_admin')

  if (userData) {
    return 'user'
  }

  if (adminData) {
    return 'admin'
  }

  return null
}

export const logUserOut = () => {
  reactLocalStorage.remove('@netshift_conference_user')
  reactLocalStorage.remove('@netshift_conference_admin')
  reactLocalStorage.remove('@netshift_conference_token')
  reactLocalStorage.remove('@netshift_conference_ref')
}
